import React from "react"
import { graphql } from "gatsby"

// Comps
import Container from "../components/containers/Container"
import { Row, Cell } from "../components/layout/Layout"
import ListArticles from "../components/lists/ListArticles"

// MISC
import Metas from "../components/metas/Metas"

const IndexPage = props => {
    return (
        <Container location={props.location}>
            <Metas
                title=""
                description="J'écris des trucs sur mon confinement"
                image=""
                url={props.location.href}
            />
            <Row>
                <Cell start="0" end="12">
                    <ListArticles data={props.data} />
                </Cell>
            </Row>
        </Container>
    )
}

export const query = graphql`
    query LayoutQuery {
        allCockpitPosts(sort: {fields: cockpitCreated, order: DESC}) {
            edges {
                node {
                    id
                    titre {
                        type
                        value
                    }
                    cockpitCreated
                    image {
                        value {
                            publicURL
                            childImageSharp {
                                fluid(
                                    quality: 85
                                    maxWidth: 800
                                    duotone: {
                                        highlight: "#FFAD00"
                                        shadow: "#36200C"
                                    }
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default IndexPage
