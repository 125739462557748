import React from "react"
import slugify from "slugify"
import { Link } from "gatsby"
import Img from "gatsby-image"

// Libs
import { isMobile } from "react-device-detect"

// Styles
import listStyles from "./ListArticles.module.styl"

// Libs
import * as moment from "moment"
import "moment/locale/fr"

class ListArticles extends React.Component {
    constructor(props) {
        super(props)

        moment.locale("fr")

        this.onMouseEnterItem = this.onMouseEnterItem.bind(this)
        this.onMouseLeaveList = this.onMouseLeaveList.bind(this)
    }

    state = {
        isHovering: false,
        image: false,
    }

    onMouseEnterItem(image) {
        this.setState({
            isHovering: true,
            image,
        })
    }

    onMouseLeaveList() {
        this.setState({
            isHovering: false,
        })
    }

    render() {
        return (
            <div className={listStyles.ListArticles}>
                <ul
                    className={listStyles.List__left}
                    onMouseLeave={!isMobile && this.onMouseLeaveList}
                >
                    {this.props.data.allCockpitPosts.edges.map(
                        ({ node }, index) => (
                            <li className={listStyles.List__Item} key={index}>
                                <Link
                                    onMouseEnter={
                                        !isMobile &&
                                        this.onMouseEnterItem.bind(
                                            this,
                                            node.image
                                        )
                                    }
                                    to={`/${slugify(node.titre.value, {
                                        replacement: "-",
                                        remove: undefined,
                                        lower: true,
                                        strict: true,
                                    })}`}
                                    className={`${listStyles.List__Item__inner}`}
                                >
                                    <h2
                                        className={`teasing-1 ${listStyles.List__Item__title}`}
                                    >
                                        {node.titre.value}
                                    </h2>


                                    <span className={`${listStyles.List__Item__Date} small`}>
                                       {moment(
                                           node.cockpitCreated
                                       ).format("LL")}
                                    </span>
                                </Link>
                            </li>
                        )
                    )}
                </ul>
                {!isMobile && (
                    <div className={listStyles.List__right}>
                        {this.state.image && (
                            <Img
                                className={`${listStyles.List__right__image} ${
                                    this.state.isHovering
                                        ? listStyles.List__right__image__active
                                        : ""
                                }`}
                                fluid={
                                    this.state.image.value.childImageSharp.fluid
                                }
                            />
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default ListArticles
